// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-prompt-input{
    border-radius: 10px;
    padding: 12px;
    font-size: 20px;
    height: 30px;
    width: calc(100% - 25px);
    border-style: dashed;
    border-width: 2px;
    resize: vertical;
    min-height: 80px;

}
.style-preview{
    border-radius: 8px;
    /*width:96px*/
    width: 100%;
    max-width: 152px;
}`, "",{"version":3,"sources":["webpack://./src/components/image/Input.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,YAAY;IACZ,wBAAwB;IACxB,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;;AAEpB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".image-prompt-input{\n    border-radius: 10px;\n    padding: 12px;\n    font-size: 20px;\n    height: 30px;\n    width: calc(100% - 25px);\n    border-style: dashed;\n    border-width: 2px;\n    resize: vertical;\n    min-height: 80px;\n\n}\n.style-preview{\n    border-radius: 8px;\n    /*width:96px*/\n    width: 100%;\n    max-width: 152px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
