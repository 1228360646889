// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result-image {
    margin-top: 20px;
    max-width: 1024px;
    border-radius: 10px;
    width: 100%;
}

#prompt-input{
  
}`, "",{"version":3,"sources":["webpack://./src/components/image/Result.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;AACf;;AAEA;;AAEA","sourcesContent":[".result-image {\n    margin-top: 20px;\n    max-width: 1024px;\n    border-radius: 10px;\n    width: 100%;\n}\n\n#prompt-input{\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
