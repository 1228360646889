import React, {useEffect, useState} from 'react';
import { Skeleton } from '@mui/material';
import './Result.css';

const Result = ({ loading, result, searchPrompt }) => {

  return (
    <>
      {loading ? (
        <Skeleton variant="rectangular" width="100%" sx={{ 'max-width': '1024px' }}>
            <img className="result-image" src='https://www.solidbackgrounds.com/images/1024x1024/1024x1024-white-solid-color-background.jpg' alt="result" />
        </Skeleton>
      ) : (
        <>
          {result.length > 0 && <img className="result-image" src={process.env.REACT_APP_BACKEND + '/images/generate?prompt=' + searchPrompt} alt="result" />}
        </>
      )}
    </>
  );
};

export default Result;
