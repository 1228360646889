// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatMic{
    width: 52px;
    height: 42px;
    cursor: pointer;
    text-align: center;
    line-height: 52px;
    border-radius: 50%;
    margin-right: 10px;
    -webkit-writing-mode: vertical-lr;
    transition : all 0.3s ease;
}

.disableChat{
    background: #121212;
    transition : all 0.3s ease;
}

.enableChat{
    background: #388e3c;
    transition : all 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/components/docs/Chat.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,iCAAiC;IACjC,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;IACnB,0BAA0B;AAC9B","sourcesContent":[".chatMic{\n    width: 52px;\n    height: 42px;\n    cursor: pointer;\n    text-align: center;\n    line-height: 52px;\n    border-radius: 50%;\n    margin-right: 10px;\n    -webkit-writing-mode: vertical-lr;\n    transition : all 0.3s ease;\n}\n\n.disableChat{\n    background: #121212;\n    transition : all 0.3s ease;\n}\n\n.enableChat{\n    background: #388e3c;\n    transition : all 0.3s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
