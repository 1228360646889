// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msg-avatar {
  width: 48px;
  height: 48px;
}

.msg-userRow {
  text-align: left;
  border-top-left-radius: 12.5px;
  border-bottom-left-radius: 12.5px;
  background-color: #388e3c;
  color: #ffffff;
}

.msg-iaRow {
  text-align: right;
  border-top-right-radius: 12.5px;
  border-bottom-right-radius: 12.5px;
  background-color: #f0f0f0;
  color:black;
}

.msg-messageRow {
  border: 1px solid #0000004a;
  padding: 14px;
  margin-bottom: 20px;
}

.msg-msg {
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 4px;
  display: inline-block;
  word-break: break-word;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/docs/Message.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,8BAA8B;EAC9B,iCAAiC;EACjC,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,+BAA+B;EAC/B,kCAAkC;EAClC,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,0JAA0J;EAC1J,eAAe;AACjB","sourcesContent":[".msg-avatar {\n  width: 48px;\n  height: 48px;\n}\n\n.msg-userRow {\n  text-align: left;\n  border-top-left-radius: 12.5px;\n  border-bottom-left-radius: 12.5px;\n  background-color: #388e3c;\n  color: #ffffff;\n}\n\n.msg-iaRow {\n  text-align: right;\n  border-top-right-radius: 12.5px;\n  border-bottom-right-radius: 12.5px;\n  background-color: #f0f0f0;\n  color:black;\n}\n\n.msg-messageRow {\n  border: 1px solid #0000004a;\n  padding: 14px;\n  margin-bottom: 20px;\n}\n\n.msg-msg {\n  padding: 8px 16px;\n  border-radius: 4px;\n  margin-bottom: 4px;\n  display: inline-block;\n  word-break: break-word;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\";\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
