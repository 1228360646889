// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzone{
    background: #212121!important;
}
.dropzone-text{
    color:white;
}
.MuiDropzoneArea-root{
    border-color: rgb(255 255 255 / 32%)!important;
}
.MuiDropzoneArea-icon{
    color: white!important;
    width: 124px!important;
    height: 119px!important;
}`, "",{"version":3,"sources":["webpack://./src/pages/ChatDocs.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;AACA;IACI,WAAW;AACf;AACA;IACI,8CAA8C;AAClD;AACA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,uBAAuB;AAC3B","sourcesContent":[".dropzone{\n    background: #212121!important;\n}\n.dropzone-text{\n    color:white;\n}\n.MuiDropzoneArea-root{\n    border-color: rgb(255 255 255 / 32%)!important;\n}\n.MuiDropzoneArea-icon{\n    color: white!important;\n    width: 124px!important;\n    height: 119px!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
