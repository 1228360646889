// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-card{
    padding: 10px;
    margin-top: 10px;
}

.item-input{
    width: calc(100% - 50px);
}

.drag-icon{
    float: right;
}

.close-title-btn{
    position: relative;
    display: contents;
   /* color: #f44336;*/
}`, "",{"version":3,"sources":["webpack://./src/components/slides/TitleList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;GAClB,mBAAmB;AACtB","sourcesContent":[".item-card{\n    padding: 10px;\n    margin-top: 10px;\n}\n\n.item-input{\n    width: calc(100% - 50px);\n}\n\n.drag-icon{\n    float: right;\n}\n\n.close-title-btn{\n    position: relative;\n    display: contents;\n   /* color: #f44336;*/\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
