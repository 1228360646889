// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    color-scheme: dark;
}
::-webkit-selection {
    background: #296b2c;
}
::selection {
    background: #296b2c ;
}
/* ::-webkit-scrollbar {
    width: 12px; 
}

::-webkit-scrollbar-thumb {
    background: #296b2c; 
}

::-webkit-scrollbar-track {
    background: #000; 
} */

* {
    scrollbar-color: #296b2c #000;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #296b2c; 
}

b{
    color: #3ca741;
}`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AAIA;IACI,mBAAmB;AACvB;AACA;IACI,oBAAoB;AACxB;AACA;;;;;;;;;;GAUG;;AAEH;IACI,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[":root {\n    color-scheme: dark;\n}\n::-moz-selection {\n    background: #296b2c;\n}\n::-webkit-selection {\n    background: #296b2c;\n}\n::selection {\n    background: #296b2c ;\n}\n/* ::-webkit-scrollbar {\n    width: 12px; \n}\n\n::-webkit-scrollbar-thumb {\n    background: #296b2c; \n}\n\n::-webkit-scrollbar-track {\n    background: #000; \n} */\n\n* {\n    scrollbar-color: #296b2c #000;\n}\n\n::-webkit-scrollbar-thumb:window-inactive {\n    background: #296b2c; \n}\n\nb{\n    color: #3ca741;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
