// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input input{
    color: white!important;
    border: #5b5b5b solid 1px!important;
    border-radius: 6px!important;
}`, "",{"version":3,"sources":["webpack://./src/pages/auth/TextField.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mCAAmC;IACnC,4BAA4B;AAChC","sourcesContent":[".input input{\n    color: white!important;\n    border: #5b5b5b solid 1px!important;\n    border-radius: 6px!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
